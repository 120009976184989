import React from 'react'
import HeroWide from '@widgets/HeroWide'
import Overlay from './Hero.Overlay'

const styles = {
  wrapper: {
    pb:1,
    pt:0
  }
}
const Hero = props => {
  const {
    location: { pathname },
    pageContext: { basePath }
  } = props

  if (pathname !== basePath) return null

  return (
    <HeroWide.Wrapper sx={styles.wrapper}>
    
    <HeroWide.Overlay>
      <Overlay />
    </HeroWide.Overlay>
  </HeroWide.Wrapper>
  )
}

export default Hero
/*return (
  <HeroWide.Wrapper>
    <HeroWide.LeftColumn>
      <Content />
    </HeroWide.LeftColumn>
    <HeroWide.RightColumn>
      <Photo />
    </HeroWide.RightColumn>
  </HeroWide.Wrapper>
)
}*/