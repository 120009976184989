import React from 'react'
import { Card, Box,  Button, Heading, Text, Link as LinkHref  } from 'theme-ui'
import thirdMonday from '@helpers/thirdMonday'

const styles = {
  wrapper: {
    maxWidth: 600,
    textAlign: `center`,
    mx: `auto`,
    py: 2
  },
  card: {
    position: `relative`
  },
  left: {
    flexBasis: `2/3`
  },
  right: {
    flexBasis: `1/3`,
    textAlign: `right`
  },
  heading: {
    color: `omegaDarker`,
    fontWeight: `bold`
  },
  subheading: {
    color: `omega`,
    mb: [3, 0]
  },
  button: {
    display: `block`,
    mx: `auto`
  }
}

const BannerHorizontal = () => {
 
 
  return (
    <Card variant='paper' sx={styles.card}>
     <Box sx={styles.wrapper}>
        <Heading>Our next monthly meeting will be held on {(thirdMonday())} at 7pm at Manly Yacht Club.</Heading>
     </Box>
      
    </Card>
  )
}

export default BannerHorizontal
/*<Box sx={styles.right}>
        <Button type='submit' sx={styles.button}>
        <LinkHref
            variant='links.nav'
            target='_blank'
            title=''
            href='/.netlify/functions/redirectZoom'
            rel='noopener'
            sx={{textDecoration: 'none',
            color:'white'}}
           >Register
           </LinkHref>
        </Button>
      </Box>*/