import React from 'react'
import {Box } from 'theme-ui'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

const styles = {
 
  imageWrapper: {
    borderRadius: `default`,
    overflow: `hidden`,
    position: `relative`,
    bg: `background`,
  },

 
}
  
export default () => {
 
  const databg = useStaticQuery(backgroundQuery)

  const { photo } = databg
  const { fluid } = (photo && photo.childImageSharp) || {}
  return (
    
  <Box sx={styles.imageWrapper}>
    <Img fluid={fluid} />
  </Box>)
}
const backgroundQuery = graphql`
  query BackgroundQuery {
    photo: file(absolutePath: { regex: "/MCF-with-text.(jpeg|jpg|gif|png)/" }) {
      childImageSharp {
        fluid(maxWidth: 1140, maxHeight: 388, cropFocus: NORTH) {
          ...GatsbyImageSharpFluid_noBase64
          width: presentationWidth
          height: presentationWidth
        }
      }
    }
  }
`
