import React from 'react'
import { Box } from 'theme-ui'
import { Stack, Hero, Main } from '@layout'
import CardList from '@components/CardList'
import Divider from '@components/Divider'
import Reveal from '@components/Reveal'
import Seo from '@widgets/Seo'
import Categories from '@widgets/Categories'
import NewsletterExpanded from '@widgets/NewsletterExpanded'
import BannerHorizontalZoom from '@widgets/BannerHorizontalZoom'
import BannerHorizontal from '@widgets/BannerHorizontal'
import BannerHorizontalExtra from '@widgets/BannerHorizontalExtra'
import HeroComponent from '../components/Hero/Hero'
import { useBlogCategories } from '@helpers-blog'

const Posts = ({ 
  data: { posts = {}, featuredPosts = {}}, 
  ...props 
}) => {
  const { pageContext: { services = {} } = {} } = props
  const categories = useBlogCategories()

  return (
    <>
      <Seo title='Home' />
      <Hero sx={{ bg: `background`, display: [`none`, `block`] }}>
        <HeroComponent {...props} />
      </Hero>
      <Hero >
        <Divider />
        <BannerHorizontal />
      </Hero>
     
      {/*<Divider />
      <Hero>  
          <NewsletterExpanded simple={true} />
      </Hero>*/}
      <Divider />
      <Hero >
        <Box sx={{ display: [`none`, `none`] }}>
          <Categories
              categories={categories}
              variant='horizontal'
              omitTitle
          />
          <Divider />
        </Box>
      </Hero>
      <Stack title="This Month's Top News" >
        <Main> 
          <CardList
            nodes={featuredPosts.nodes}
            variant='vertical-cover'
            limit={3}
            columns={[1, 1, 3]}
            slider
            omitCategory
            omitAuthor
          />     
        </Main >
      </Stack >
      <Divider />
      <Stack title="Recent News" >
        <Main>
          <CardList
            nodes={featuredPosts.nodes}
             variant={['horizontal-md', 'horizontal']}
            limit={8} 
            skip={3}
             columns={[1, 2, 2, 2]}
            omitCategory
            omitAuthor
            withTitleLink
          />
        </Main >
      </Stack >
      <Divider />
      <Stack> 
        <Main>
          {posts.group &&
            posts.group.map(group => (
              <>
                <Divider />
               
                { (group.categoryName !== 'Newsletters' &&
                  group.categoryName !== 'Minutes')
                && (
                  <Reveal>
                  <CardList
                    nodes={group.nodes}
                    variant={['horizontal-md', 'horizontal']}
                    limit={4}
                    columns={[1, 2, 2]}
                    title={group.categoryName}
                    omitCategory
                    omitAuthor
                    withTitleLink
                  />
                  <Divider space={2} />
                  </Reveal>
                )}

                  
              </>
            ))}  
       
        </Main>
      </Stack>
      
      
    </>
  )
}

export default Posts
